export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~4],
		"/admin/settings": [~5],
		"/auth/verify-me": [~6],
		"/database/[table]": [~7],
		"/onboarding": [~8,[2]],
		"/onboarding/complete": [9,[2]],
		"/onboarding/profile": [10,[2]],
		"/onboarding/workspace": [11,[2]],
		"/reports": [~12],
		"/reports/[id]": [~13],
		"/reports/[id]/old": [~14],
		"/respond/[id]": [~15,[3]],
		"/signin": [~16],
		"/surveys": [~17],
		"/surveys/[id]": [~18],
		"/tasks": [19],
		"/team": [20],
		"/team/[id]": [21]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';